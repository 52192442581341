import React, { Component } from "react";
import "./App.css";
import { URL_API } from "../src/constantes/url";

import request from "superagent";

// subcomponents
import FiltroForm from "./components/FiltroForm";
import GridBusqueda from "./components/GridBusqueda";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busqueda: [],
      deshabilitar: false,
      loading: false
    };
  }

  Habilitar() {
    this.setState({ deshabilitar: true });
  }

  async GetFiltro(data) {
    if (data.producto) {
      this.setState({ loading: true, busqueda: [] });
      this.Habilitar();
      await request.get(URL_API + '/precio')
      //await request.get(URL_API + "/precio_tiempo_real")
        .query({ categoria: data.categoria })
        .query({ producto: data.producto })
        .query({ presentacion: data.presentacion })
        .query({ pais: data.pais })
        .query({ pagina: data.pagina })
        .timeout(120000)
        .then(res => {
          const busquedaJson = JSON.parse(res.text);
          this.setState({ busqueda: busquedaJson, loading: false });
        })
        .catch(err => {
          this.setState({ loading: false, busqueda: [], deshabilitar: false });
          console.log(err);
        });
    } else {
      this.setState({ busqueda: [] });
    }

    this.setState({ deshabilitar: false });
  }

  render() {
    return (
      <div className="App">
        <nav className="navbar navbar-dark bg-dark">
          <a className="navbar-brand" href="/">
            Price Comparison
          </a>
        </nav>

        <div className="container">
          <div className="text-centermt mt-2">
            <FiltroForm
              filtro={this.GetFiltro.bind(this)}
              habilitar={this.state.deshabilitar}
            ></FiltroForm>
          </div>
          <div className="text-center mt-2">
            <GridBusqueda
              info={this.state.busqueda}
              loading={this.state.loading}
            ></GridBusqueda>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
