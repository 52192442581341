import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from '../App';
import {URL_API} from '../constantes/url';
import request from 'superagent';


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",

            busqueda: "",

            login: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit(e) {

        e.preventDefault();

        let data = {
            username: this.state.username,
            password: this.state.password
        }

        this.setLogin(data)
    }

    async setLogin(data) {
       
        if (data.username) {

            await
                request
                    .get(URL_API+'/login')
                    .query({ username: data.username })
                    .query({ password: data.password })
                    .then(res => {
                        const loginJson = JSON.parse(res.text)
                        this.setState({ login: loginJson })
                    });

            if (this.state.login) {
                ReactDOM.render(<App />, document.getElementById('root'))
            }
        }
    }


    render() {
        return (
            <div className="Login">
                <nav className="navbar navbar-dark bg-dark">
                    <a className="navbar-brand" href="/">
                        Login
                </a>
                </nav>
                <div className="container mt-2 col-sm col-md-4" >
                    <form className="card" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="card-body">
                            <div className="form-group">
                                <label>User</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Username"
                                    name="User"
                                    value={this.state.username}
                                    onChange={event => this.setState({ username: event.target.value })}
                                    required />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter Password"
                                    name="Password"
                                    value={this.state.password}
                                    onChange={event => this.setState({ password: event.target.value })}
                                    required />
                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="dropdownCheck2" />
                                    <label className="form-check-label">Remember me</label>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <button type="submit" className="btn btn-outline-primary">Sign in</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Login;