import React, { Component } from 'react';
import request from 'superagent';
import { URL_API } from '../constantes/url';

class FiltroForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

            categorias: [],
            productos: [],
            presentaciones: [],
            paises: [],
            paginas: [],

            categoria: 'Choose...',
            producto: 'Choose...',
            presentacion: 'Choose...',
            pais: 'Choose...',
            pagina: 'Choose...',

            data: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.CleanForm = this.CleanForm.bind(this);
    }

    handleSubmit(e) {

        e.preventDefault();
        this.setState({ deshabilitar: true })
        let data = {
            categoria: this.state.categoria,
            producto: this.state.producto,
            presentacion: this.state.presentacion,
            pais: this.state.pais,
            pagina: this.state.pagina
        }

        this.props.filtro(data)
    }

    CleanForm() {

        this.props.filtro({})        
        
        this.setState({ categoria: 'Choose...' })
        this.setState({ producto: 'Choose...' })
        this.setState({ presentacion: 'Choose...' })
        this.setState({ pais: 'Choose...' })
        this.setState({ pagina: 'Choose...' })

        this.setState({ busqueda: this.state.data })
    }

    componentDidMount() {

        request
            .get(URL_API + '/datos')
            .end((err, res) => {

                const datosJson = JSON.parse(res.text);
                this.setState({ data: datosJson })
            });
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.data !== prevState.data) {

            this.setState({ categorias: [...new Set(this.state.data.map(it => it.categoria))] })
            this.setState({ productos: [...new Set(this.state.data.map(it => it.producto))] })
            this.setState({ presentaciones: [...new Set(this.state.data.map(it => it.presentacion))] })
            this.setState({ paises: [...new Set(this.state.data.map(it => it.pais))] })
            this.setState({ paginas: [...new Set(this.state.data.map(it => it.web))] })
        }

        if (this.state.categoria !== prevState.categoria) {

            let Search = this.state.data;

            if (this.state.categoria !== 'Choose...') {

                Search = Search.filter(it => it.categoria === this.state.categoria)
            }

            this.setState({ productos: [...new Set(Search.map(it => it.producto))] })
            this.setState({ presentaciones: [...new Set(Search.map(it => it.presentacion))] })
            this.setState({ paises: [...new Set(Search.map(it => it.pais))] })
            this.setState({ paginas: [...new Set(Search.map(it => it.web))] })

            this.setState({ producto: 'Choose...' })
            this.setState({ presentacion: 'Choose...' })
            this.setState({ pais: 'Choose...' })
            this.setState({ pagina: 'Choose...' })
        }

        if (this.state.producto !== prevState.producto) {

            let Search = this.state.data;
           
            if (this.state.categoria !== 'Choose...') {

                Search = Search.filter(it => it.categoria === this.state.categoria)
            }

            if (this.state.producto !== 'Choose...') {

                Search = Search.filter(it => it.producto === this.state.producto)
            }

            this.setState({ presentaciones: [...new Set(Search.map(it => it.presentacion))] })
            this.setState({ paises: [...new Set(Search.map(it => it.pais))] })
            this.setState({ paginas: [...new Set(Search.map(it => it.web))] })

            this.setState({ presentacion: 'Choose...' })
            this.setState({ pais: 'Choose...' })
            this.setState({ pagina: 'Choose...' })
        }

        if (this.state.presentacion !== prevState.presentacion) {

            let Search = this.state.data;
            
            if (this.state.categoria !== 'Choose...') {

                Search = Search.filter(it => it.categoria === this.state.categoria)
            }

            if (this.state.producto !== 'Choose...') {

                Search = Search.filter(it => it.producto === this.state.producto)
            }

            if (this.state.presentacion !== 'Choose...') {

                Search = Search.filter(it => it.presentacion === this.state.presentacion)
            }

            this.setState({ paises: [...new Set(Search.map(it => it.pais))] })
            this.setState({ paginas: [...new Set(Search.map(it => it.web))] })

            this.setState({ pais: 'Choose...' })
            this.setState({ pagina: 'Choose...' })
        }

        if (this.state.pais !== prevState.pais) {

            let Search = this.state.data;
            
            if (this.state.categoria !== 'Choose...') {

                Search = Search.filter(it => it.categoria === this.state.categoria)
            }

            if (this.state.producto !== 'Choose...') {

                Search = Search.filter(it => it.producto === this.state.producto)
            }

            if (this.state.presentacion !== 'Choose...') {

                Search = Search.filter(it => it.presentacion === this.state.presentacion)
            }

            if (this.state.pais !== 'Choose...') {

                Search = Search.filter(it => it.pais === this.state.pais)
            }

            this.setState({ paginas: [...new Set(Search.map(it => it.web))] })

            this.setState({ pagina: 'Choose...' })
        }
    }

    render() {

        return (
            <form id="filtro" className="card " onSubmit={this.handleSubmit.bind(this)}>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-12 col-md">
                            <label>Category</label>
                            <select
                                name="categoria"
                                className="form-control"
                                value={this.state.categoria}
                                onChange={event => this.setState({ categoria: event.target.value })}
                                disabled={this.props.habilitar}>
                                <option>Choose...</option>
                                {
                                    this.state.categorias.map((item, i) => {
                                        return (
                                            <option key={i}>{item}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group col-12 col-md">
                            <label>Product</label>
                            <select
                                name="producto"
                                className="form-control"
                                value={this.state.producto}
                                onChange={event => this.setState({ producto: event.target.value })}
                                disabled={this.props.habilitar}>
                                <option>Choose...</option>
                                {
                                    this.state.productos.map((item, i) => {
                                        return (
                                            <option key={i}>{item}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group col-12 col-md">
                            <label>Presentation</label>
                            <select
                                name="presentacion"
                                className="form-control"
                                value={this.state.presentacion}
                                onChange={event => this.setState({ presentacion: event.target.value })}
                                disabled={this.props.habilitar}>
                                <option>Choose...</option>
                                {
                                    this.state.presentaciones.map((item, i) => {
                                        return (
                                            <option key={i}>{item}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group col-12 col-md">
                            <label>Country</label>
                            <select
                                name="pais"
                                className="form-control"
                                value={this.state.pais}
                                onChange={event => this.setState({ pais: event.target.value })}
                                disabled={this.props.habilitar}>
                                <option>Choose...</option>
                                {
                                    this.state.paises.map((item, i) => {
                                        return (
                                            <option key={i}>{item}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group col-12 col-md">
                            <label>Web</label>
                            <select
                                name="pagina"
                                className="form-control"
                                value={this.state.pagina}
                                onChange={event => this.setState({ pagina: event.target.value })}
                                disabled={this.props.habilitar}>
                                <option>Choose...</option>
                                {
                                    this.state.paginas.map((item, i) => {
                                        return (
                                            <option key={i}>{item}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="text-right">

                        <button onClick={this.CleanForm.bind(this)} type="button" className="btn btn-outline-primary">Clear</button>
                        <button type="submit" className="btn btn-outline-primary ml-1">Search</button>

                    </div>

                </div>
            </form >
        )
    }
}

export default FiltroForm;