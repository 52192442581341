import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import loading from '../img/loading.gif'

class GridBusqueda extends Component {
    render() {
        return (
            <div className="table-responsive">
                <div className="row col">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-outline-primary"
                        table="table-to-xls"
                        filename="Price Comparison"
                        sheet="Comparison"
                        buttonText="Download as EXCEL" />
                </div>
                <table className="table table-hover table-sm  mt-2" id="table-to-xls">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Presentation</th>
                            <th scope="col">Country</th>
                            <th scope="col">Web</th>
                            <th scope="col">Rate</th>
                            <th scope="col">Price</th>
                            <th scope="col">Variation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.info.length === 0 ?
                                this.props.loading === true ?
                                    <tr><td colSpan="7"><img src={loading} alt="" className="loading" /></td></tr> :
                                    <tr><td colSpan="7">No Data</td></tr> :
                                this.props.info.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>({item.categoria}) - {item.producto}</td>
                                            <td>{item.presentacion}</td>
                                            <td>{item.pais}</td>
                                            <td><p style={{ margin: '0px' }}><a href={item.link} target="_blank" rel="noopener noreferrer"  >{item.pagina}</a></p></td>
                                            <td>{item.tarifa}{item.moneda}</td>
                                            <td>{item.precio === 0 ? <p style={{ margin: '0px' }}>N/A</p> : <p style={{ margin: '0px' }}>{item.precio}{item.moneda}</p>}</td>
                                            <td>{item.precio === 0 ? <p style={{ margin: '0px' }}>N/A</p> : item.variacion < 0 ?
                                                (<p className="fas fa-long-arrow-alt-down" style={{ color: 'red', margin: '0px' }}>{Math.abs(item.variacion)}{item.moneda}</p>) :
                                                (<p className="fas fa-long-arrow-alt-up" style={{ color: 'green', margin: '0px' }}>{item.variacion}{item.moneda}</p>)}
                                            </td>
                                        </tr>)
                                })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default GridBusqueda;