function url() {
	let url = 'http://localhost:8080/comparador/comparador_api';
	//let url = 'http://wildapi.dwitsites.com';
	if( process.env.NODE_ENV === 'production') {
		//url = 'https://wildtaste.herokuapp.com';
		url = 'https://wildapi.dwitsites.com';
	}
	return url
}

export const URL_API = url();